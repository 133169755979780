/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  & > div {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    max-width: 80%;
    width: 25%; /* default is small size */

    /* Center the modal on small screens */
    @media (max-width: 600px) {
      & {
        width: 90%;
      }
    }

    &.small {
      width: 25%;
    }

    &.medium {
      width: 45%;
    }

    &.large {
      width: 70%;
      min-height: 50vh;
    }

    &.xlarge {
      width: 80%;
      min-height: 50vh;
    }

    & > button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      cursor: pointer;
      padding: 1px 6px;
      color: black;
      display: flex;
      border: 0px;
      background: none;
    }

    & > h2 {
      color: #ff9966;
    }
  }
}
