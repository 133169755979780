@import '../../../themes/variables';

.set-password-container {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: $lightGray;
}

#set-password-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  margin: 170px auto 50px auto;
  padding: 4.5rem 4rem;
  box-shadow: 11px 10px 38px 0 rgb(46 63 99 / 15%);
  border-radius: 5px;
  background: white;

  @media only screen and (max-width: 600px) {
    width: 85%;
    padding: 1rem;
    align-items: center;
  }

  @media only screen and (min-width: 600px) and (max-width: 920px) {
    width: 65%;
    padding: 2rem;
    align-items: center;
  }

  > div {
    width: 100%; /* Adjust the width and margin as needed */
    margin-bottom: 0px; /* Adjust the margin as needed */
    padding: 0px;
  }
}

.set-password {
  margin: 2% 3%;
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 2fr 2fr 2fr 5fr 2fr;
  gap: 1rem;
}

.label {
  place-self: center;
}

.button-set-password {
  font: inherit;
  cursor: pointer;
  padding: 1px 6px;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 50%;
  margin-left: 96%;
}

.button-action {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 1.625rem;
  margin-left: 1rem;
}

.button-action:hover,
.button-action:active {
  background-color: $accentOrange;
  border-color: $accentOrange;
}

.buttons {
  grid-column: 4 / 5;
  place-self: end;
  text-align: right;
}

.set-password-input {
  padding: 10px 5px;
  width: 100%;
}

.error {
  color: $error;
}
