@import '../../../themes/variables';

.course-evaluate {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  align-items: flex-end !important;
  margin-bottom: 15px;

  > div {
    margin-bottom: 0.5rem;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0 1px;

      svg {
        font-size: xx-large;
        text-align: left;
        width: 28px;
        height: 28px;
      }
    }

    & + div {
      display: flex;
      flex-direction: column;
      text-align: right;

      p {
        margin-top: 10px;
      }

      @media only screen and (max-width: 600px) {
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}
