html {
  box-sizing: border-box;
  width: 100%;
  font-family: 'Comfortaa', cursive;
}
*,
*::after,
*::before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  font-family: Comfortaa;
}
