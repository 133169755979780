@import '../../themes/variables';

.pagination-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
}

// Pagination CSS
.pagination {
  text-align: center;
  float: none;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style: none;

  .page-item {
    .page-link {
      color: $orange;
      background: $white;
      position: relative;
      display: block;
      cursor: pointer;
      text-decoration: none;
      padding: 0.7rem 01rem;
      border: 1px solid $lightGray;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    &:first-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:not(:first-child) {
      .page-link {
        margin-left: -1px;
      }
    }

    &.disabled {
      .page-link {
        color: #6c757d;
        pointer-events: none;
        background-color: $white;
        border-color: $lightGray;
      }
    }

    &.active {
      .page-link {
        z-index: 3;
        color: $white;
        background-color: $orange;
        border-color: $orange;
      }
    }
  }
}
