@import '../../../themes/variables';

.course-details {
  > div {
    .course-information {
      padding-bottom: 1rem;
      > div {
        border: none;
        margin-bottom: 1rem;

        > div {
          padding-top: 0px;
        }
      }
    }

    & + div {
      display: flex;
      flex-direction: row;
      margin: 0rem 1.5rem 1.5rem 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid #bbb;

      > div {
        flex: 2;

        & + div {
          flex: 1;
          // margin-top: 0.5rem;
        }
      }
    }
  }
}
