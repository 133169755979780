@import '../../../themes/variables';

.course-review {
  margin: 0px;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      flex: 2 !important;
      padding-top: 1rem !important;
      padding-bottom: 0px !important;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.25rem;

        a {
          text-decoration: none;

          h2 {
            color: #000000;
          }
        }

        & + div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 1rem;

            > p {
              &:first-child {
                font-weight: bold;
                margin-top: 0.5rem;
              }

              &:nth-child(3) {
                width: 100%;
              }

              a {
                color: $orange;
                cursor: pointer;
                text-decoration: none;
                outline: none;
              }
            }
          }
        }
      }

      & + div {
        flex: 1;
        padding-top: 2rem;
      }
    }

    & + div {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: none;
      overflow-y: scroll;
      max-height: 275px;

      > div {
        flex: 1;
        padding: 1rem 0.5rem 0rem 0rem;

        > p {
          margin-bottom: 0.5rem;

          &:first-child {
            font-size: 18px;
            font-weight: bold;
          }

          &:nth-last-child(2) {
            white-space: pre-line;
          }

          &:last-child {
            padding-bottom: 15px;
            border-bottom: 1px solid #bbb;
          }
        }

        > div {
          font-weight: bold;
          margin-bottom: 0.5rem;

          & + div {
            display: flex;
            align-items: row;
            flex-wrap: wrap;
            margin: -0.25rem -0.25rem;

            > div {
              border: none;
              color: black;
              padding: 6px 12px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              margin: 0.25rem;
              border-radius: 1.625rem;
              white-space: nowrap;
              font-size: medium;
              background-color: $orangeWOpacityAsRGB;
            }
          }
        }

        & + div {
          padding-right: 0rem;

          > div {
            & + div {
              > div {
                background-color: $turquoiseWOpacityAsRGB;
              }
            }
          }
        }
      }

      > p {
        margin-top: 1rem;
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }

    @media only screen and (max-width: 900px) {
      align-items: center;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0 1rem 1rem 1rem;
  }
}
