.login-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin: 6% 0;

  .heading {
    text-align: center;
    padding: 0 3% 3% 3%;
  }

  .title {
    padding-bottom: 3%;
  }

  .textbox {
    padding: 2%;
    font-weight: bold;
  }

  .email-login {
    text-align: center;

    > a {
      color: #000000;
      font-size: 15px;
      font-weight: bold;
      text-decoration: none;
      padding: 12px 15px 12px 52px;
      border: 1px solid #ccc;
      border-radius: 5px;

      svg {
        position: absolute;
        margin-top: -8px;
        margin-left: -45px;
      }
    }
  }
}
