@import '../../../themes/variables';

.course-information {
  margin: 0px;
  padding: 1.5rem;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid #bbb;

    > div {
      flex: 1;
      padding-top: 2rem;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25rem;

        &.skills-pan {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1.5rem;

          > div {
            > div {
              font-weight: bold;
              margin-bottom: 0.5rem;

              & + div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: -0.25rem -0.25rem;
                justify-content: flex-start;

                > div {
                  border: none;
                  color: black;
                  padding: 6px 12px;
                  text-align: center;
                  text-decoration: none;
                  display: inline-block;
                  margin: 0.25rem;
                  border-radius: 1.625rem;
                  white-space: nowrap;
                  font-size: small;
                  background-color: $orangeWOpacityAsRGB;
                }
              }
            }

            & + div {
              > div {
                margin-top: 1.5rem;

                & + div {
                  margin-top: 0px;
                  > div {
                    background-color: $turquoiseWOpacityAsRGB;
                  }
                }
              }
            }
          }
        }

        a {
          text-decoration: none;

          h2 {
            color: #000000;
          }
        }

        & + div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 1rem;

            > p {
              &:first-child {
                font-weight: bold;
                margin-top: 0.5rem;
              }

              &:nth-child(3) {
                width: 100%;
                max-height: 4.5rem;
                overflow-y: scroll;
                white-space: pre-line;
              }

              a {
                color: $orange;
                cursor: pointer;
                text-decoration: none;
                outline: none;
              }
            }
          }
        }
      }

      & + div {
        flex: 1;
        padding-top: 2rem;
      }
    }

    & + div {
      display: flex;
      flex-direction: row;
      width: 100%;
      border: none;

      > div {
        flex: 1;
        padding: 1rem 0.5rem 0rem 0rem;

        > div {
          font-weight: bold;
          margin-bottom: 0.5rem;

          & + div {
            display: flex;
            align-items: row;
            flex-wrap: wrap;
            margin: -0.25rem -0.25rem;
            justify-content: flex-start;

            > div {
              border: none;
              color: black;
              padding: 6px 12px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              margin: 0.25rem;
              border-radius: 1.625rem;
              white-space: nowrap;
              font-size: small;
              background-color: $orangeWOpacityAsRGB;
            }
          }
        }

        & + div {
          padding-right: 0rem;

          > div {
            & + div {
              > div {
                background-color: $turquoiseWOpacityAsRGB;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }

    @media only screen and (max-width: 900px) {
      align-items: center;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0 1rem 1rem 1rem;
  }
}
