@import '../../../themes/variables';

.sort-by-select {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.5rem;
  width: 100%;
}

.sort-by-dropdown {
  position: relative;
  width: 120%;
  max-width: 31.25rem;
  background-color: $white;
  float: left;
  border-radius: 1.625rem;
  margin-left: 16px;

  select {
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #b7b7b7;
  }
}
