@import '../../../themes/variables';

.statistics {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  margin-left: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  align-items: flex-end !important;

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;

    > div {
      &:first-child,
      &:last-child {
        text-align: left;
        padding: 0px;
        font-weight: bold;
        margin-bottom: 15px;
        font-size: 12px;
        color: #006961;
        width: 50px;
      }

      &:last-child {
        padding: 0px;
        width: 40px;
        text-align: right;
      }

      &:nth-child(2) {
        width: 190px;
        background-color: #ddd;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 15px;

        > div {
          width: 0;
          height: 22px;
          background-color: #f78e64;
          transition: width 2s ease;
        }
      }
    }
  }
}
