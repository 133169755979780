/* Confirm Modal Styles */
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  & > div {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    max-width: 80%;

    /* Center the modal on small screens */
    @media (max-width: 600px) {
      & {
        width: 90%;
      }
    }

    &.small {
      width: 25%;
    }

    &.medium {
      width: 45%;
    }

    &.large {
      width: 70%;
    }

    &.xlarge {
      width: 80%;
    }

    & > div {
      & > button {
        top: 10px;
        right: 10px;
        border: none;
        font-size: 24px;
        cursor: pointer;
        background-color: #ff9966;
        font: inherit;
        padding: 10px 15px;
        color: white;
        margin-top: 25px;
      }
    }

    & > h2 {
      color: #ff9966;
    }

    & > p {
      margin-top: 25px;
    }
  }
}
