@import '../../../themes/variables';

#set-role-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 100%; /* Adjust the width and margin as needed */
    margin-bottom: 0px; /* Adjust the margin as needed */
    padding: 0px;
  }

  > h2 {
    color: #ff9966;
  }
}

.set-role {
  margin: 2% 3%;
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 2fr 2fr 2fr 5fr 2fr;
  gap: 1rem;
}

.label {
  place-self: center;
}

.button-set-role {
  font: inherit;
  cursor: pointer;
  padding: 1px 6px;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 50%;
  margin-left: 96%;
}

.button-action {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 1.625rem;
  margin-left: 1rem;
}

.button-action:hover,
.button-action:active {
  background-color: $accentOrange;
  border-color: $accentOrange;
}

.buttons {
  grid-column: 4 / 5;
  place-self: end;
  text-align: right;
}

.set-role-input {
  padding: 10px 5px;
  width: 100%;
}

.error {
  color: $error;
}
