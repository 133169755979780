@import '../../../themes/variables';

.label {
  place-self: center;
}

.label-textarea {
  place-self: center;
  align-self: start;
  padding-top: 1%;
}

@media only screen and (max-width: 600px) {
  .modal-overlay > div.large {
    width: 90%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 920px) {
  .modal-overlay > div.large {
    width: 90%;
  }
}
