@import '../../../themes/variables';

.filter-button {
  position: relative;
  width: 60%;
  background-color: $white;
  float: left;
  border-radius: 1.625rem;
  margin-left: 16px;
  border: none;
  cursor: pointer;
}

.link-style {
  text-decoration: none;
  color: black;
}
