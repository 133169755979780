@import '../../../themes/variables';

#add-review-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2% 3%;
  grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 2fr 2fr 2fr 5fr 2fr;
  gap: 1rem;
  min-height: 25vh;

  > div {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
    padding: 0px;
    align-items: center;

    &.buttons {
      grid-column: 4 / 5;
      place-self: end;
      text-align: right;

      .button-action {
        font: inherit;
        cursor: pointer;
        padding: 1rem 2rem;
        border: 1px solid $orange;
        background-color: $orange;
        color: $white;
        border-radius: 1.625rem;
        margin-left: 1rem;

        &:hover,
        &:active {
          background-color: $accentOrange;
          border-color: $accentOrange;
        }
      }

      > div {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin-top: -170px;

        > button {
          margin-left: 0px;
          margin-top: 2rem !important;
        }
      }
    }

    &:nth-last-child(2) {
      align-items: normal;
    }

    &:last-child {
      justify-content: right;
      position: absolute;
      width: 90%;

      @media only screen and (max-width: 900px) {
        justify-content: center;
        position: relative;
        width: 90%;
      }
    }

    > div {
      width: 130px;

      > label {
        place-self: center;
      }

      & + div {
        width: 100%;

        .input-fields {
          padding: 10px 5px;
          width: 60%;
        }

        .error {
          color: $error;
        }
      }
    }

    @media only screen and (max-width: 900px) {
      align-items: flex-start !important;
      flex-direction: column;
    }
  }
}
