@import '../../../themes/variables';

.search-field {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.5rem;
  width: 70%;
}

.search-input {
  width: 80%;
  border: 1px solid $white;
}

.wrapper {
  width: 100%;
  max-width: 31.25rem;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.8rem;
  background: $white;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
}

#searchQuerySubmit,
#searchQueryRemove {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover,
#searchQueryRemove:hover {
  cursor: pointer;
}

#searchQueryRemove > img {
  width: 24px;
  height: 24px;
}
