.right-container {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.button-box {
  @media only screen and (max-width: 600px) {
    padding-bottom: 1.5rem;
  }
}

.user-items {
  margin: 0 2rem 2rem 2rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 11px 10px 38px 0 rgb(46 63 99 / 15%);
  border-radius: 5px;
  background: white;

  @media only screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0 1rem 1rem 1rem;
    align-items: center;
  }

  & > div {
    padding: 10px;

    & > img {
      width: 96px;
      border-radius: 10px;
    }

    & + div {
      width: 90%;

      & > div {
        display: flex;
        justify-content: space-between;

        h2 {
          color: #ff9966;
        }

        div {
          display: flex;
          flex-wrap: nowrap;

          button {
            font: inherit;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #ff9966;
            background-color: #ff9966;
            color: white;
            margin-right: 8px;
            border-radius: 1.625rem;
          }
        }

        & + div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & > div {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 1rem;
            width: 60%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
