@import '../../../themes/variables';

#create-course-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem 2rem 2rem;

  > div {
    width: calc(50% - 8px); /* Adjust the width and margin as needed */
    padding: 0px;

    &:nth-child(9) {
      margin-top: -60px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;

    > div {
      &:nth-child(9) {
        margin-top: -40px;
      }
    }
  }
}

.create-course {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 2fr 2fr 2fr 5fr 2fr;
  gap: 1rem;
}

.create-course-input {
  padding: 10px 5px;
  width: 100%;
}

.create-course-checkbox {
  padding: 10px 5px;
}

.button-action {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem !important;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 12px;
  margin-left: 1rem;
  margin-top: 0 !important;

  @media only screen and (max-width: 600px) {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }
}

.button-action:hover,
.button-action:active {
  background-color: $accentOrange;
  border-color: $accentOrange;
  transition: 0.3s;
}

.error {
  color: $error;
}

.create-course-heading {
  padding: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: x-large;
    padding: 1rem;
  }
}

.label {
  place-self: center;
}

.label-checkbox {
  margin-right: 20px;
}

.label-textarea {
  place-self: center;
  align-self: start;
  padding-top: 1%;
}

.footer {
  width: 100% !important;
  display: flex;
  padding: 10px 1.5rem;
  flex-direction: row-reverse;
  align-items: center;
}

.dropdown-element {
  display: flex;
  flex-direction: row;
}

.dropdown-divs {
  width: 50% !important;
  padding: 10px 5px;
}
