$orange: #f78e64;
$orangeWOpacityAsRGB: rgba(247, 142, 100, 0.44);
$accentOrange: #cc6a42;
$white: #ffffff;
$black: #000000;
$lightGray: #f2f2f2;
$darkGray: #cccccc;
$deepGray: #aaaaaa;
$qlueBlack: #192634;
$qlueDarkBlack: #121b25;
$turquoise: #6aafa7;
$turquoiseWOpacityAsRGB: rgba(106, 175, 167, 0.44);
$starsRating: #ffd449;
$error: #c45060;
$success: #60a561;

$smallMargin: 1rem; // mobile
$bigMargin: 2rem; // desktop
