@import '../../themes/variables';

#edit-course-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem 2rem 2rem;

  > div {
    width: calc(50% - 8px); /* Adjust the width and margin as needed */
    margin-bottom: 16px; /* Adjust the margin as needed */
    padding: 0px;

    &:nth-child(9) {
      margin-top: -60px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;

    > div {
      &:nth-child(9) {
        margin-top: -40px;
      }
    }
  }
}

.edit-course {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 2fr 2fr 2fr 5fr 2fr;
  gap: 1rem;
}

.edit-course-input {
  padding: 10px 5px;
  width: 100%;
}

.edit-course-checkbox {
  padding: 10px 5px;
}

.buttons {
  grid-column: 4 / 5;
  place-self: end;
}

.button-action {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 1.625rem;
  margin-left: 1rem;

  @media only screen and (max-width: 600px) {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }
}

.button-action:hover,
.button-action:active {
  background-color: $accentOrange;
  border-color: $accentOrange;
}

.error {
  color: $error;
  margin-top: 8px;
}
