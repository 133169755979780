@import '../../../themes/variables';

.filter-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2rem;
  padding: 2rem 0rem 1rem 0rem;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    margin: 0 1rem;
  }
}

.tags-container {
  margin: 0 2rem 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.filter-bar-left {
  margin-left: 0rem;
  margin-right: 16px;
  flex: 1;
  flex-grow: 1;
  @media only screen and (max-width: 600px) {
    font-size: x-large;
  }
}

.filter-bar-right {
  display: flex;
  flex-direction: row;
}

.skills-tags-button {
  border: none;
  color: black;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.25rem;
  border-radius: 1.625rem;
  white-space: nowrap;
  font-size: medium;
  background-color: $darkGray;
}
