@import '../../themes/variables';

.navbar {
  height: 80px;
  width: 100%;
  background: $qlueBlack;
  color: $orange;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.nav-logo {
  text-decoration: none;
  color: $orange;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.nav-link {
  text-decoration: none;
  color: $white;
  transition: 0.4s all;
  padding-left: 20px;
  align-self: center;
  width: 100%;
}

.nav-link:hover {
  color: $deepGray;
}

.nav-link.active {
  color: $orange;
}

.nav-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.button-add-new-course {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  color: $white;
  border: 1px solid $orange;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 1.625rem;
  transition: 0.4s all;
}

.button-add-new-course:hover {
  color: $black;
  background-color: $orange;
}

@media only screen and (max-width: 600px) {
  .navbar {
    position: relative;
  }

  .nav-link {
    padding-left: 0;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 80px;
    left: -100%;
    transition: 0.5s all;
  }

  .nav-links.active {
    background: $qlueDarkBlack;
    left: 0;
  }

  .nav-item {
    padding: 10px 0;
  }

  .nav-icon {
    display: flex;
  }

  .button-add-new-course {
    margin-left: 0;
  }
}
