@import '../../../themes/variables';

.course-card {
  margin: 0 2rem 2rem 2rem;
  padding: 1.5rem;
  justify-content: space-between;
  box-shadow: 11px 10px 38px 0 rgb(46 63 99 / 15%);
  border-radius: 5px;
  background: $white;

  @media only screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0 1rem 1rem 1rem;
  }
}

.course-card-top {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }
}
.course-card-top-left {
  flex: 1;
  padding: 0.5rem;
}
.course-card-top-right {
  padding: 0.5rem;
}
.course-card-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.course-card-bottom-left,
.course-card-bottom-right {
  flex: 1;
  padding: 0.5rem;
}

.course-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.course-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;

  .course-source {
    font-weight: bold;
  }
}

.course-skills {
  display: flex;
  flex-direction: column;
}

.course-skills-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.course-skills-pill-container {
  display: flex;
  align-items: row;
  flex-wrap: wrap;
  margin: -0.25rem -0.25rem;
}

.course-skills-pill-required {
  border: none;
  color: black;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.25rem;
  border-radius: 1.625rem;
  white-space: nowrap;
  font-size: small;
  background-color: $orangeWOpacityAsRGB;
}

.course-skills-pill-learned {
  border: none;
  color: black;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.25rem;
  border-radius: 1.625rem;
  white-space: nowrap;
  font-size: small;
  background-color: $turquoiseWOpacityAsRGB;
}

.course-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}

.course-title {
  color: $orange;
  cursor: pointer;
  text-decoration: none;
}

.course-description {
  width: 80%;
  white-space: pre-line;
}

.button-delete-course {
  font: inherit;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-edit-course {
  font: inherit;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid $orange;
  background-color: $orange;
  color: $white;
  margin-right: 8px;
  border-radius: 1.625rem;
}

.button-container-group {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem 0.5rem;
  right: 0;
}
