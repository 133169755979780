.not-found {
  text-align: center;
  margin-bottom: 10%;

  @media only screen and (max-width: 600px) {
    img {
      width: 380px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 920px) {
    img {
      width: 450px;
    }
  }

  > a {
    outline: none;
    text-decoration: none;
    background-color: black;
    color: white;
    padding: 12px 25px 10px 25px;
    border-radius: 20px;
    font-weight: bold;
  }
}
