@import '../../../themes/variables';

.user-component {
  background: $lightGray;
  height: 100%;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}
