@import '../../../themes/variables';

.starrating {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 1px;

    svg {
      font-size: xx-large;
      text-align: left;
      width: 28px;
      height: 28px;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
}
